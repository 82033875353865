/* Octopus Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Page
*/

module.exports = {
  /* 01.General */

  /* 02.Inicio de sesión de usuario, cierre de sesión, registro */

  /* 03.Menú */
  'menu.home': 'Inicio',
  'menu.app': 'Inicio',
  'menu.device': 'Device List [ES]',
  'menu.malware': 'Malware List [ES]',
  'menu.reports': 'Reports [ES]',

  /* 04.Page  */
  'pages.error-title': 'Vaya, parece que ha ocurrido un error!',
  'pages.error-code': 'Código de error',
  'pages.go-back-home': 'REGRESAR A INICIO',
  'pages.orderby': 'Order By [ES] : ',
  'pages.delete': 'Delete [ES]',
  'pages.another-action': 'Another action [ES]',
  'pages.display-options': 'Opciones de Pantalla',

  /* Reports */
  'reports.pha.title': 'PHA REPORTS',
};

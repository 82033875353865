import {
  SET_CURRENT_PAGE,
  SET_SELECTED_PAGE_SIZE,
  SET_SELECTED_ORDER_OPTION,
  SET_SEARCH,
  SET_HAS_NEXT_MALWARE,
} from '../actions';

export const setCurrentPage = (payload) => ({
  type: SET_CURRENT_PAGE,
  payload,
});

export const setSelectedPageSize = (payload) => ({
  type: SET_SELECTED_PAGE_SIZE,
  payload,
});

export const setSelectedOrderOption = (payload) => ({
  type: SET_SELECTED_ORDER_OPTION,
  payload,
});

export const setSearch = (payload) => ({
  type: SET_SEARCH,
  payload,
});

export const setHasNextMalware = (payload) => ({
  type: SET_HAS_NEXT_MALWARE,
  payload,
});
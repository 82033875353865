/* Octopus Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Page
05.anomaly
*/

module.exports = {
  /* 01.General */

  /* 02.User Login, Logout, Register */
  'user.login-title': 'Login',
  'user.register': 'Register',
  'user.forgot-password': 'Forgot Password',
  'user.email': 'E-mail',
  'user.name': 'User Name',
  'user.password': 'Password',
  'user.forgot-password-question': 'Forget password?',
  'user.fullname': 'Full Name',
  'user.login-button': 'LOGIN',
  'user.register-button': 'REGISTER',
  'user.reset-password-button': 'RESET',
  'user.buy': 'BUY',
  'user.username': 'Username',
  'user.new-password-again': 'New Password Again',
  'user.new-password': 'New Password',
  'user.reset-password': 'Reset Password',
  'user.enterUsername': 'Please enter your user name',
  'user.enterPassword': 'Please enter your password',
  'user.loginError': 'Login Error',
  'user.checkUsernamePassword': 'Please check your User Name / Password',
  'user.octopusPanel': 'OCTOPUS PANEL',
  'user.useCredentials': 'Please use your credentials to login.',
  'user.forgotCredentials': 'If you do not remember your credentials, you can contact with Codevo.',
  'user.ldapServiceError': 'Failed to obtain ldap services.',
  'user.ldapServiceTitle': 'Ldap Service',

  /* 03.Menu */
  'menu.home': 'Home',
  'menu.app': 'Home',
  'menu.device': 'Device',
  'menu.browser': 'Browser',
  'menu.script': 'Script',
  'menu.application': 'App',
  'menu.malware': 'Malware',
  'menu.reports': 'Reports',
  'menu.file': 'File',
  'menu.admin': 'Admin',

  /* 04 Page */
  'pages.error-title': 'Ooops... looks like an error occurred!',
  'pages.error-code': 'Error code',
  'pages.go-back-home': 'GO BACK HOME',
  'pages.orderby': 'Order By : ',
  'pages.delete': 'Delete',
  'pages.another-action': 'Another action',
  'pages.display-options': 'Display Options',
  'unauthorized.title': 'Unauthorized Access Attempt',
  'unauthorized.detail':
    'You are not authorized to view the page you are trying to access. Make contact with admin.',

  'pages.device.detail.general-tab': 'GENERAL',
  'pages.device.detail.malware-tab': 'MALWARE',
  'pages.device.detail.app-tab': 'INSTALLED APP',
  'pages.device.detail.anomaly-tab': 'ANOMALY',
  'pages.device.detail.general-users': 'USERS',
  'pages.device.detail.general-users-cutoff-button': 'Mark as Safe',
  'pages.device.detail.general-users-cutoff-again-button': 'Mark as Safe Again',
  'pages.device.detail.general-detail': 'DEVICE DETAIL',
  'pages.device.detail.general-app': 'APPLICATIONS',
  'pages.device.detail.general-app-create-task': 'Get App Detail',
  'pages.device.detail.general-app-apk-task': 'Get Apk',
  'pages.device.detail.general-app-create-push-task': 'Create Push Task',
  'pages.device.detail.general-app-create-app-list-task':
    'Refresh App List',
  'pages.device.detail.general-app-check-app-request':
    'Check App Exists',
  'pages.device.detail.general-app-device-parameters':
    'Device Specific Parameters',
  'pages.device.detail.general-app-create-app-list-task-again':
    'Create App List Push Task Again',
  'pages.device.detail.check-app-package-name' : 'Package Name',
  'pages.device.detail.general-app-task-list': 'Task List',
  'pages.device.detail.general-app-cancel-task': 'Cancel Task',
  'pages.device.runtime.list-tab': 'RUNTIME',
  'pages.device.task.list-tab': 'TASK',
  'pages.device.settings-tab': 'SETTINGS',
  'pages.device.settings-tag-added': 'Previously Added Tags',
  'pages.device.no-tags': 'No tags found.',
  'pages.device.settings-tag': 'Tag: ',
  'pages.device.settings-tag-first-seen': 'First Seen: ',
  'pages.device.settings-tag-last-seen': 'Last Seen: ',
  'pages.device.settings-tag-title': 'New Tag',
  'pages.device.settings-tag-button': 'Add Tag',
  'pages.device.settings-tag-success': 'Tag added.',
  'pages.device.settings-tag-fail': 'Failed to add tag',
  'pages.device.settings-tag-delete-title': 'Delete Tag',
  'pages.device.settings-tag-delete-success': 'Tag deleted.',
  'pages.device.settings-tag-delete-fail': 'Failed to delete tag',
  'pages.device.settings-get-add-tag-fail': 'Failed to obtain tags to add',
  'pages.device.settings-get-add-tag-title': 'Tags to Add',
  'pages.device.settings-tag-info': 'You can prevent transactions from being made on the device using the BLOCK tag, prevent the application from running on the device using the CRASH tag, or ensure that a specified tag is added to the device at every runtime.',
  'pages.device.task.new-tab': 'NEW TASK',
  'pages.device.task.forms.app-select': 'Push Application',
  'pages.device.task.forms.text': 'Text Input',
  'pages.device.task.forms.task-app': 'Application',
  'pages.device.detail.runtime-detail': 'Show Detail',
  'pages.device.detail.runtime-wifi': 'Wifi Info Detected',
  'pages.device.detail.runtime-touch': 'Touch Detected',
  'pages.device.detail.runtime-multiple-touch': 'Multiple Touch Type Detected',
  'pages.device.detail.runtime-identifier-change': 'Identifier Change Detected',
  'pages.browser.detail.general-detail': 'BROWSER DETAIL',
  'task.type.3': 'Installed Application List',
  'task.type.4': 'Application Detail',
  'task.type.5': 'Send Log',
  'task.type.6': 'Send Apk',
  'task.type.7': 'Scan All Devices',
  'task.type.8': 'Refresh Device Parameters',

  'pages.malware.detail.devices-tab': 'DEVICES',
  'pages.malware.detail.management-tab': 'MANAGEMENT',
  'pages.malware.detail.comments-tab': 'COMMENTS',
  'pages.malware.detail.appAudits-tab': 'APP AUDITS',

  'pages.script.detail.devices-tab': 'DEVICES',
  'pages.script.detail.sourceCode-tab': 'SOURCE CODE',
  'pages.script.detail.scriptAudits-tab': 'SCRIPT AUDITS',

  'button.click-here': 'Search',
  'button.show-more': 'Show more',
  'button.show-details': 'Show details',
  'button.hide-details': 'Hide details',
  'device.search.placeholder': 'Type a search query (e.g. OCTOPUS-ID: id)',
  'device.search.detail.list.user-id': 'Lists devices by user-id',
  'device.search.detail.list.octopus-id': 'Lists devices by octopus-id',
  'device.search.detail.list.android-id': 'List devices by android-id',
  'device.search.detail.list.vendor-id': 'List ios devices by vendor-id',
  'device.search.detail.list.anomaly': 'Lists devices by anomalies',
  'device.search.detail.list.emulator': 'Lists emulators',
  'device.search.detail.list.accessibility': 'Lists accessibility enabled devices',
  'device.search.detail.list.runtimeHook': 'Lists runtime hook detected devices',
  'device.search.detail.list.applicationName': 'Lists devices by application names',
  'device.search.detail.list.showApplicationNames': 'Show application names',
  'device.search.detail.applicationNames': 'Application Names',

  'device.list.brand': 'Brand: ',
  'device.list.model': 'Model: ',
  'device.list.modelNo': 'Model No: ',
  'device.list.osVersion': 'OS Version: ',
  'device.list.osApiLevel': 'OS API Level: ',
  'device.list.vendorId': 'Vendor Id: ',
  'device.list.androidId': 'Android Id: ',
  'device.list.octopusId': 'Octopus Id: ',
  'device.list.create': 'Create: ',
  'device.list.lastScan': 'Last Scan: ',
  'device.list.applicationNames': 'Application Names: ',
  'device.list.noMalware': 'No Malware',
  'device.list.installation': 'Installation: ',
  'device.list.lastSeen': 'Last Seen: ',

  'device.detail.imei': 'Imei',
  'device.detail.wifiMac': 'Wifi Mac',
  'device.detail.manufacturer': 'Manufacturer',
  'device.detail.buildSerialNo': 'Build Serial No',
  'device.detail.deviceReport': 'Device Report',
  'device.detail.package': 'Package',
  'device.detail.versionName': 'Version Name',
  'device.detail.versionCode': 'Version Code',
  'device.detail.octopusVersion': 'Octopus Version',
  'device.detail.lastScan': 'Last Scan',
  'device.detail.firstInstall': 'First Install',
  'device.detail.lastUpdate': 'Last Update',
  'device.detail.identifier': 'Identifier',
  'device.detail.copyClipboard': 'Copy user ids to clipbard',
  'device.detail.apkSize': 'Apk Size: ',
  'device.detail.installation': 'Installation: ',
  'device.detail.firstSeen': 'First Seen: ',
  'device.detail.update': 'Update: ',
  'device.detail.lastSeen': 'Last Seen: ',
  'device.detail.sources': 'Sources',
  'device.detail.entries': 'entries',
  'device.detail.show': 'Show ...',
  'device.detail.accessibilityActive': 'Accessibility Active',
  'device.detail.noEntries': 'No entries',
  'device.detail.iconSHA1': 'Icon SHA1:',
  'device.detail.apkSha256Hash': 'Apk Sha256 Hash:',
  'device.detail.certificateSHA1': 'Certificate SHA1:',
  'device.detail.primaryCertHash': 'Primary Cert Hash:',
  'device.detail.permissions': 'Permissions',
  'device.detail.installed': 'INSTALLED',
  'device.detail.uninstalled': 'UNINSTALLED',
  'device.detail.runtime': 'Runtime',

  'sort.createASC': 'Create Date | ASC',
  'sort.createDESC': 'Create Date | DESC',
  'sort.firstSeenDateDESC': 'First Seen Date | DESC',
  'sort.firstSeenDateASC': 'First Seen Date | ASC',
  'sort.lastSeenDateDESC': 'Last Seen Date | DESC',
  'sort.lastSeenDateASC': 'Last Seen Date | ASC',
  'sort.installationTimeDESC': 'Installation Time | DESC',
  'sort.installationTimeASC': 'Installation Time | ASC',
  'sort.firstSeenDate': 'First Seen Date',
  'sort.firstSeen': 'First Seen',
  'sort.lastExecutionTimeDESC': 'Last Execution Time | DESC',

  'malware.category.BLACKLISTED': 'BLACKLISTED',
  'malware.category.MALWARE': 'MALWARE',
  'malware.category.TARGETED_MALWARE': 'TARGETED_MALWARE',
  'malware.category.WHITELISTED': 'WHITELISTED',
  'malware.category.UNKNOWN': 'UNKNOWN',
  'malware.category.RANSOMWARE': 'RANSOMWARE',
  'malware.category.PHISHING': 'PHISHING',
  'malware.category.TROJAN': 'TROJAN',
  'malware.category.FRAUDWARE': 'FRAUDWARE',
  'malware.category.TOLL_FRAUD': 'TOLL_FRAUD',
  'malware.category.WAP_FRAUD': 'WAP_FRAUD',
  'malware.category.CALL_FRAUD': 'CALL_FRAUD',
  'malware.category.BACKDOOR': 'BACKDOOR',
  'malware.category.SPYWARE': 'SPYWARE',
  'malware.category.GENERIC_MALWARE': 'GENERIC_MALWARE',
  'malware.category.HARMFUL_SITE': 'HARMFUL_SITE',
  'malware.category.WINDOWS_MALWARE': 'WINDOWS_MALWARE',
  'malware.category.HOSTILE_DOWNLOADER': 'HOSTILE_DOWNLOADER',
  'malware.category.NON_ANDOID_THREAT': 'NON_ANDROID_THREAT',
  'malware.category.ROOTING': 'ROOTING',
  'malware.category.PRIVILAGE_ESCALATION': 'PRIVILAGE_ESCALATION',
  'malware.category.TRACKING': 'TRACKING',
  'malware.category.SPAM': 'SPAM',
  'malware.category.DENIAL_OF_SERVICE': 'DENIAL_OF_SERVICE',
  'malware.category.DATA_COLLECTION': 'DATA_COLLECTION',
  'malware.category.DO NOT OVERRIDE': 'DO NOT OVERRIDE',

  'device.detail.createApkTask': 'Create Apk Task',
  'device.detail.createApkTaskMessage': 'Silent push will be sent to the device and the apk will be received. Do you approve?',
  'device.detail.createAppDetailTask': 'Create App Detail Task',
  'device.detail.createAppDetailTaskMessage': 'Silent push will be sent to the device and the application detail will be received.',
  'device.detail.createAppListTask': 'Create App List Task',
  'device.detail.createAppListTaskMessage': 'Silent push will be sent to the device and the installed applications information will be received. Do you approve?',
  'device.detail.includeAppIcon': 'Include app icon',
  'device.detail.includePermissions': 'Include permissions',
  'device.detail.includeApkHash': 'Include apk hash',
  'device.detail.checkApplication': 'Check Application',
  'device.detail.check': 'Check',
  'device.detail.packageNameEmpty': 'Package name cannot be empty.',
  'device.detail.appExists': 'This app exists in this device.',
  'device.detail.appDoesNotExist': 'This app does not exist in this device.',
  'device.detail.bankName': 'Bank applications cannot be searched.',
  'device.detail.consumedRuntime': 'Consumed runtime per load',
  'device.detail.monthLimit': 'Month limit',
  'device.detail.loadMoreAnomalies': 'Load More Anomalies',
  'device.detail.lastRuntimes': 'Last Runtimes',
  'device.detail.historicalRuntimes': 'Historical Runtimes',
  'device.detail.application': 'Application: ',
  'device.detail.create': 'Create: ',
  'device.detail.lastModified': 'Last Modified: ',
  'device.detail.tags': 'Tags',
  'device.detail.createTask': 'Create Task',
  'device.detail.getDeviceLog': 'Get Octopus Log',
  'device.detail.getCustomLog': 'Get Seal Log',
  'device.detail.selectApp': 'Select App',
  'device.detail.scanAllDevices': 'Scan All Devices',
  'device.detail.type': 'Type: ',
  'device.detail.status': 'Status: ',
  'device.detail.createDate': 'Create Date: ',
  'device.detail.body': 'Body: ',
  'device.detail.createButton': 'Create',
  'device.detail.downloadAPK': 'Download APK',
  'device.detail.downloadLog': 'Download Log',
  'device.detail.lastSafeMark': 'Last Safe Mark: ',
  'device.detail.markUserSafeMessage': 'Are you sure you want to mark the user as safe for this device?',
  'device.detail.lastCall': 'Last Call: ',
  'device.detail.lastIncomingCall': 'Last Incoming Call: ',

  'device.detail.taskError': 'Task Error',
  'device.detail.taskErrorMessage': 'The Octopus SDK version is not compatible for this task.',
  'device.detail.taskErrorMessage2': 'The Octopus SDK version is not enough to do this.',
  'device.detail.cancelTask': 'Cancel Task',
  'device.detail.taskCancelMessage': 'The task will be cancelled. Do you approve?',
  'device.detail.selectionError': 'Selection Error',
  'device.detail.selectionErrorMessage': 'Please select an application.',

  'app.search.placeholder': 'Type a search query (e.g. PKG: com.lorem.ipsum)',
  'app.search.detail.list.category': 'Lists all PHAs that are the same as the category name entered. Valid category names : UNKNOWN, TARGETED_MALWARE, WHITELISTED, BLACKLISTED...',
  'app.search.detail.list.pkg': 'Lists all PHAs that are the same as the package name entered.',
  'app.search.detail.list.apk-hash-hex': 'finds the app that are the same as the apk hash (hex) entered.',
  'app.search.detail.list.icon-sha1-hex': 'Lists all PHAs that are the same as the logo sha1 hash (hex) entered.',
  'app.search.detail.list.cert-sha1-hex': 'Lists all PHAs that are the same as the certificate sha1 (hex) fingerprint entered.',
  'app.search.detail.list.label-like': 'Lists all PHAs with the label like to the value entered.',
  'app.search.detail.list.permission': 'Lists all PHAs with the have the permission like to the value entered.',
  'app.search.detail.list.tag': 'Lists all PHAs with the tag entered. (Octopus.ACCESSIBILITY, Octopus.DEBUG_KEY etc ...)',
  'app.search.detail.list.day-range': 'Lists all PHAs created within the entered number of days range.',

  'app.list.firstSeenDate': 'First Seen Date : ',
  'app.list.apkHash': 'Apk Hash: ',
  'app.list.iconSHA1': 'Icon SHA1: ',
  'app.list.certificateSHA1': 'Certificate SHA1: ',

  'app.detail.appName': 'App Name',
  'app.detail.packageName': 'Package Name',
  'app.detail.apkHash': 'Apk Hash',
  'app.detail.iconSHA1': 'Icon SHA-1',
  'app.detail.certificates': 'Certificates',
  'app.detail.apkSize': 'Apk Size',
  'app.detail.category': 'Category',
  'app.detail.createDate': 'Create Date',
  'app.detail.lastModifiedDate': 'Last Modified Date',
  'app.detail.permissions': 'Permissions',
  'app.detail.entries': 'entries',
  'app.detail.show': 'Show ...',
  'app.detail.manifest': 'Manifest',
  'app.detail.androidManifestFile': 'Android Manifest File',
  'app.detail.tags': 'Tags',
  'app.detail.users': 'Users',
  'app.detail.usersAffected': 'users affected',
  'app.detail.noEntries': 'No entries',
  'app.detail.noAffectedUsers': 'No affected users',
  'app.detail.brand': 'Brand: ',
  'app.detail.model': 'Model: ',
  'app.detail.osVersion': 'OS Version: ',
  'app.detail.osApiLevel': 'OS API Level: ',
  'app.detail.securityPatchLevel': 'Security Patch Level: ',
  'app.detail.androidId': 'Android Id: ',
  'app.detail.octopusId': 'Octopus Id: ',
  'app.detail.firstSeen': 'First Seen:',
  'app.detail.lastSeen': 'Last Seen: ',
  'app.detail.installation': 'Installation: ',
  'app.detail.malwareCategoryMessage': 'You can change the malware category by choosing below.',
  'app.detail.save': 'Save',
  'app.detail.malwareChangeMessage': 'Are you sure you want to change the malware category?',
  'app.detail.currentValue': 'Current Value: ',
  'app.detail.oldValue': 'Old Value: ',
  'app.detail.field': 'Field: ',
  'app.detail.changeDate': 'Change Date: ',
  'app.detail.user': 'User: ',

  'browser.search.placeholder': 'Type a search query (e.g. OCTOPUS-ID : id)',
  'browser.search.detail.list.user-id': 'Lists devices by user-id',
  'browser.search.detail.list.octopus-id': 'Lists devices by octopus-id',
  'browser.search.detail.list.anomaly': 'Lists devices by anomalies',
  'browser.search.detail.list.emulator': 'Lists emulators',
  'browser.search.detail.list.accessibility': 'Lists accessibility enabled devices',
  'browser.search.detail.list.webDriver': 'Lists web driver detected devices',

  'browser.list.brand': 'Brand: ',
  'browser.list.version': 'Version: ',
  'browser.list.os': 'OS: ',
  'browser.list.engine': 'Engine: ',
  'browser.list.octopusId': 'Octopus Id: ',
  'browser.list.create': 'Create: ',
  'browser.list.lastScan': 'Last Scan: ',
  'browser.list.noMalware': 'No Malware',
  'browser.list.firstSeen': 'First Seen: ',
  'browser.list.lastSeen': 'Last Seen: ',
  'browser.list.installation': 'Installation: ',
  'browser.list.model': 'Model: ',

  'browser.detail.manufacturer': 'Manifacturer',
  'browser.detail.platform': 'Platform',
  'browser.detail.timeZone': 'Time Zone',
  'browser.detail.canvas': 'Canvas',
  'browser.detail.browserHash': 'Browser Hash',
  'browser.detail.userAgent': 'User Agent',
  'browser.detail.appVersion': 'App Version',
  'browser.detail.cpuCores': 'CPU Cores',
  'browser.detail.deviceMemory': 'Device Memory',
  'browser.detail.maxTouchPoints': 'Max Touch Points',
  'browser.detail.deviceReport': 'Device Report',
  'browser.detail.host': 'Host',
  'browser.detail.spiderVersion': 'Spider Version',
  'browser.detail.lastScan': 'Last Scan',
  'browser.detail.firstVisit': 'First Visit',
  'browser.detail.copyClipboard': 'Copy user ids to clipboard',
  'browser.detail.markUserSafeMessage': 'Are you sure you want to mark the user as safe for this device?',
  'browser.detail.unspecified': 'Unspecified',
  'browser.detail.scriptSize': 'Script Size: ',
  'browser.detail.firstSeen': 'First Seen: ',
  'browser.detail.lastSeen': 'Last Seen: ',
  'browser.detail.installed': 'INSTALLED',
  'browser.detail.uninstalled': 'UNINSTALLED',
  'browser.detail.sha256': 'SHA256: ',
  'browser.detail.sha1': 'SHA1: ',
  'browser.detail.md5': 'MD5: ',
  'browser.detail.ssdeep': 'SSDEEP: ',

  'script.list.firstSeenDate': 'First Seen Date: ',
  'script.list.sha256': 'SHA256: ',
  'script.list.sha1': 'SHA1: ',
  'script.list.md5': 'MD5: ',
  'script.detail.label': 'Label',
  'script.detail.source': 'Source',
  'script.detail.sha256': 'SHA256',
  'script.detail.sha1': 'SHA1',
  'script.detail.md5': 'MD5',
  'script.detail.ssdeep': 'SSDEEP',
  'script.detail.scriptSize': 'Script Size',
  'script.detail.category': 'Category',
  'script.detail.createDate': 'Create Date',
  'script.detail.lastModifiedDate': 'Last Modified Date',
  'script.detail.tags': 'Tags',
  'script.detail.entries': 'entries',
  'script.detail.show': 'Show ...',
  'script.detail.noEntries': 'No entries',
  'script.detail.users': 'Users',
  'script.detail.noAffectedUsers': 'No affected users',
  'script.detail.usersAffected': 'users affected',
  'script.detail.brand': 'Brand: ',
  'script.detail.version': 'Version: ',
  'script.detail.osVersion': 'OS Version: ',
  'script.detail.engine': 'Engine: ',
  'script.detail.octopusId': 'Octopus Id: ',
  'script.detail.firstSeen': 'First Seen: ',
  'script.detail.lastSeen': 'Last Seen: ',
  'script.detail.copyToClipboard': 'Copy to Clipboard',
  'script.detail.copied': 'Copied!',
  'script.detail.download': 'Download',
  'script.detail.highlight': 'Highlight',
  'script.detail.fetchSourceCode': 'Fetch Source Code',
  'script.detail.changeCategoryMessage': 'You can change the malware category by choosing below and you can set a new label.',
  'script.detail.save': 'Save',
  'script.detail.malwareUpdateMessage': 'Are you sure you want to update the malware?',

  'report.availableReports': 'Available Reports',
  'report.reportName': 'Report Name : ',
  'report.generateNewReport': 'GENERATE NEW REPORT',
  'report.previouslyGeneratedReports': 'Previously Generated Reports',
  'report.deleteReports': 'Delete Reports',
  'report.deleteReportsMessage': 'All reports will be deleted. Do you approve?',
  'report.refreshPageWarning': 'Refresh Page Warning',
  'report.refreshPageWarningMessage': 'Please refresh page for reports',
  'report.validateName': 'Please enter a value',

  'file.name': 'Name: ',
  'file.size': 'Size: ',
  'file.status': 'Status: ',
  'file.id': 'Id: ',
  'file.lastExecutionTime': 'Last Execution Time: ',

  'users.createUser': 'Create User',
  'users.userName': 'User Name: ',
  'users.name': 'Name: ',
  'users.email': 'Email: ',
  'users.admin': 'ADMIN',
  'users.editor': 'EDITOR',
  'users.reviewer': 'REVIEWER',
  'users.createdBy': 'Created By: ',
  'users.createDate': 'Create Date: ',
  'users.lastSuccessfulLogin': 'Last Successful Login: ',
  'users.lastFailedLogin': 'Last Failed Login: ',
  'users.edit': 'Edit',
  'users.delete': 'Delete',
  'users.create': 'Create',
  'users.editUserRole': 'Edit User Role',
  'users.save': 'Save',
  'users.deleteUser': 'Delete User',
  'users.deleteUserMessagePart1': 'The user ',
  'users.deleteUserMessagePart2': ' will be deleted. Do you approve?',
  'users.createUserError': 'Create User Error',
  'users.createUserErrorMessage': 'Fill the User Name',
  'users.createUserErrorMessage2': 'Please check new user info',
  'users.editUserError': 'Edit User Error',
  'users.editUserErrorMessage': 'Same user role selected',

  'rule.changeRuleStatus': 'Change Rule Status',
  'rule.changeRuleStatusMessage': 'Are you sure you want to change the status of this rule?',
  'rule.pipelineUpdate': 'Pipeline Update',
  'rule.pipelineUpdateMessage': 'Pipeline status updated.',
  'rule.messageUpdate': 'Message Update',
  'rule.messageUpdateMessage': 'Pipeline message updated.',
  'rule.pipelineUpdateError': 'Pipeline Update Error',
  'rule.updateFail': 'Update fail!',
  'rule.cacheRefreshed': 'Cache refreshed.',
  'rule.cacheError': 'Cache Error',
  'rule.cacheFail': 'Cache fail!',
  'rule.editMessage': 'Edit Message',
  'rule.apply': 'Apply',
  'rule.emptyMessage': 'Message cannot be empty.',

  'pages.admin.user-tab': 'USERS',
  'pages.admin.profile-tab': 'PROFILE',
  'pages.admin.newuser-tab': 'NEW USER',
  'pages.admin.other-tab': 'OTHER',
  'pages.admin.create-user-button': 'Create User',
  'pages.admin.create-user-username': 'User Name',
  'pages.admin.create-user-password': 'Password',
  'pages.admin.create-user-email': 'Email',
  'pages.admin.create-user-firstname': 'First Name',
  'pages.admin.create-user-lastname': 'Last Name',
  'pages.admin.edit-user': 'Edit',
  'pages.admin.edit-delete': 'Delete',
  'pages.admin.file-operations-tab': 'FILE OPERATIONS',
  'pages.admin.pipeline-operations-tab': 'RULE CONFIG',
  'pages.admin.request-log-tab': 'REQUEST LOG',
  'pages.admin.parameter-tab': 'PARAMETERS',
  'pages.menu.filter': 'FILTER',
  'pages.menu.pipeline': 'ACTION',
  'pages.request-log.status': 'Status',
  'pages.request-log.user-name': 'User Name',
  'pages.request-log.url': 'URL',
  'pages.request-log.method': 'Method',
  'pages.request-log.start-date': 'Start Date',
  'pages.request-log.end-date': 'End Date',
  'pages.request-log.request': 'Apply',
  'pages.pipeline.apply': 'Apply All Changes',
  'pages.no-data': 'Record not found.',
  'pages.no': 'No',
  'pages.yes': 'Yes',
  'pages.cancel': 'Cancel',
  'pages.close': 'Close',
  'pages.confirmation': 'Confirmation',
  'pages.requestloglist.table.user': 'User',
  'pages.requestloglist.table.method': 'Method',
  'pages.requestloglist.table.context-root': 'Context Root',
  'pages.requestloglist.table.url': 'Url',
  'pages.requestloglist.table.description': 'Description',
  'pages.requestloglist.table.start-date': 'Start Date',
  'pages.requestloglist.table.elapsed-time': 'Elapsed Time (ms)',
  'pages.requestloglist.table.status': 'Status',
  'pages.requestloglist.menu.start-date': 'Start Date',
  'pages.requestloglist.menu.end-date': 'End Date',
  'pages.requestloglist.menu.user': 'User',
  'pages.requestloglist.menu.method': 'Method',
  'pages.requestloglist.menu.context-root': 'Context Root',
  'pages.requestloglist.menu.url': 'Url',
  'pages.requestloglist.menu.status': 'Status',
  'pages.requestloglist.desc.login': 'Request sent when logging into the panel',
  'pages.requestloglist.desc.parameter': 'Sent automatically on every login. Parameter control is made. For example, obtaining the app names list for icon blurring.',
  'pages.requestloglist.desc.getDeviceList': 'Request sent when device menu is clicked and device list page is opened',
  'pages.requestloglist.desc.getDeviceDetail': 'Request sent when device detail page is opened',
  'pages.requestloglist.desc.getDeviceAppList': 'Request sent when INSTALLED APP tab is opened in device detail screen.',
  'pages.requestloglist.desc.getDevicePhaList': 'Request sent when MALWARE tab is opened in device detail screen.',
  'pages.requestloglist.desc.createAppListTask': 'Request sent when the button is clicked to obtain the app list in INSTALLED APP page.',
  'pages.requestloglist.desc.getDeviceAnomalies_v2': 'Request sent when ANOMALY tab is opened in device detail page',
  'pages.requestloglist.desc.deviceApplicationRuntime_v2': 'Request sent when RUNTIME tab is opened in device detail page',
  'pages.requestloglist.desc.getTasks': 'Request sent when TASK tab is opened in device detail page',
  'pages.requestloglist.desc.createLogTask': 'Request sent when a new task is created to obtain log from device in TASK page',
  'pages.requestloglist.desc.getPhaList': 'Request sent when APP tab is clicked to obtain malware info',
  'pages.requestloglist.desc.getPhaDetail': 'Request sent when a malware detail is opened in APP page',
  'pages.requestloglist.desc.getPhaDevices': 'Request sent when malware detail page is opened to obtain the devices that the malware is installed in',
  'pages.requestloglist.desc.appAudit/appId': 'Request sent when APP AUDITS tab is clicked in device detail page',
  'pages.requestloglist.desc.updatePhaCategory': 'Request sent in order to change the category of the app in MANAGEMENT tab of app detail page',
  'pages.requestloglist.desc.reportDefinitions': 'Request sent when report menu is clicked',
  'pages.requestloglist.desc.previousReports': 'Request sent when a report is clicked in the report menu',
  'pages.requestloglist.desc.generateReport': 'Request sent when a new report is created',
  'pages.requestloglist.desc.uploadJSONFile': 'Request sent when a file is uploaded to the admin menu',
  'pages.requestloglist.desc.getFiles': 'Request sent when the files uploaded to the admin menu are listed',
  'pages.requestloglist.desc.executionResult': 'Request sent when the result of the files uploaded to the admin menu are downloaded',
  'pages.requestloglist.desc.requestLog': 'Request sent when the REQUEST LOG tab in admin menu is opened',
  'pages.requestloglist.desc.getUsers': 'Request sent when the users are listed in admin menu',
  'pages.requestloglist.desc.createUser': 'Request sent when a new user is created in the admin menu. Roles: Reviewer=0, Editor=1, Admin=2',
  'pages.requestloglist.desc.editUser': 'Request sent when the role of a user is changed in the admin menu',
  'pages.requestloglist.desc.deleteUser': 'Request sent when a user is deleted in the admin menu',
  'pages.requestloglist.desc.pipelineOperationList': 'Request sent when RULE CONFIG tab is opened in the admin menu',
  'pages.requestloglist.desc.updatePipelineEnableStatus': 'Request sent when a rule is activated or deactivated in RULE CONFIG tab',
  'pages.requestloglist.desc.increaseCacheVersion': 'Request sent when "Apply all changes" button is pressed in RULE CONFIG tab',
  'pages.requestloglist.desc.getDeviceSpecificTags': 'Request sent to list the tags that are added to the device in device detail page SETTINGS tab.',
  'pages.requestloglist.desc.getTagsToAdd': 'Request sent to list the tags that can be added to the device in device detail page SETTINGS tab.',
  'pages.requestloglist.desc.addDeviceSpecificTag': 'Request sent to add a tag to the device in device detail page SETTINGS tab.',
  'pages.requestloglist.desc.deleteDeviceSpecificTag': 'Request sent to delete a tag of a device in device detail page SETTINGS tab.',
  'pages.requestloglist.desc.getBrowserList': 'Request sent when browser menu is clicked and browser list page is opened',
  'pages.requestloglist.desc.getLdapServices': 'Request sent to obtain LDAP services on login page',
  'pages.requestloglist.desc.getPhsList': 'Request sent when script menu is clicked and script list page is opened',
  'pages.requestloglist.desc.deviceDiagnose': 'Request sent to obtain device report in device detail page',
  'pages.requestloglist.desc.pipelineOperations': 'Request sent to obtain rules in rule config page',
  'pages.requestloglist.desc.getBrowserDetail': 'Request sent when browser detail page is opened',
  'pages.requestloglist.desc.browserDiagnose': 'Request sent to obtain browser report in browser detail page',
  'pages.requestloglist.desc.getBrowserAnomalies_v2': 'Request sent when ANOMALY tab is opened in browser detail page',
  'pages.requestloglist.desc.browserApplicationRuntime_v2': 'Request sent when RUNTIME tab is opened in browser detail page',
  'pages.requestloglist.desc.getDevicePhsList': 'Request sent when MALWARE tab is opened in browser detail screen.',
  'pages.requestloglist.desc.registerReportDefinition': 'Request sent to create a new report definition for reports page',
  'pages.requestloglist.desc.downloadPreviousReport': 'Request sent to download previous report on reports page',

  'pages.parameterslist.table.name': 'Name',
  'pages.parameterslist.table.value': 'Value',
  'pages.parameterslist.table.desc': 'Description',
  'pages.parameterslist.table.target': 'Target',
  'pages.parameterslist.table.pilotPercentage': 'Pilot',
  'pages.parameterslist.target.GENERAL': 'PLATFORM',
  'pages.parameterslist.desc.ess': 'Enables darkening the screen in Android devices on remote access. Can be activated for 3 different conditions: (Remote access app / Malware / App with accessibility authorization). Can be activated for different combination of these cases. Takes 3 bit value. 111 means 7, its active on all cases.',
  'pages.parameterslist.desc.vcss': 'Informs screen sharing on apps like Whatsapp.',
  'pages.parameterslist.desc.ebss': 'Blurs the screen when the application in on background.',
  'pages.parameterslist.desc.callst': 'Parameter used for Android search data. Can take values 1, 3, 7. Used on old SDK versions. 1 takes search data, 3 sends the data to the backend, 7 sends the data to the backend and inform the mobile app through SDK.',
  'pages.parameterslist.desc.callstfr': 'Two more parameters added to the one used by the new SDK\'s. Sample callstfr: "3; 120; 300". First value is same as callst. Second value is the period of informing the Octopus backend the search data. It will be informed in every x seconds regardless of the amount of new searches. Third value is the period of informing the Mobile banking app the search data by the SDK.',
  'pages.parameterslist.desc.ham': '49 means its active for all apps that are installed from unknown sources. 56 means its active for all devices.',
  'pages.parameterslist.desc.siaabf': 'Boolean for accessing the device apps in order to use bloom filter.',
  'pages.parameterslist.desc.ucc': 'Boolean for using a checkup cache. If true, if the current checkup request is the same as the last one, the value in the cache is returned.',
  'pages.parameterslist.desc.mcc': 'Anti keylogging parameter. Used to obtain keyboard information from unknown install sources on Android devices.',
  'pages.parameterslist.desc.immo': 'Anti keylogging parameter. Used to obtain keyboard information from unknown install sources on Android devices.',
  'pages.parameterslist.desc.tchintdvcid': '(TOUCH_HINT_DEVICE_ID)',
  'pages.parameterslist.desc.tchintdvcnm': '(TOUCH_INTERCEPTOR_DEVICE_NAME)',
  'pages.parameterslist.desc.tchintsz': '(TOUCH_INTERCEPTOR_SIZE)',
  'pages.parameterslist.desc.tchvnt': '(TOUCH_EVENT)',
  'pages.parameterslist.name.ess': 'Enable Screen Security',
  'pages.parameterslist.name.vcss': 'Voip Call Screen Security',
  'pages.parameterslist.name.ebss': 'Enable Background Screen Security',
  'pages.parameterslist.name.callst': 'Call State',
  'pages.parameterslist.name.callstfr': 'Call State with Frequency',
  'pages.parameterslist.name.ham': 'Android Anti Keylogging Parameter',
  'pages.parameterslist.name.siaabf': 'Send Installed Apps as Bloom Filter',
  'pages.parameterslist.name.ucc': 'Update Checkup Cache',
  'pages.parameterslist.name.mcc': 'Metric Collection',
  'pages.parameterslist.name.immo': 'Input Methods Metric Options',
  'pages.parameterslist.name.tchintdvcid': 'Touch Hint Device ID',
  'pages.parameterslist.name.tchintdvcnm': 'Touch Interceptor Device Name',
  'pages.parameterslist.name.tchintsz': 'Touch Interceptor Size',
  'pages.parameterslist.name.tchvnt': 'Touch Event',
  'pages.parameterslist.value.ess.0': 'None',
  'pages.parameterslist.value.ess.1': 'Access Authorization App',
  'pages.parameterslist.value.ess.2': 'Malware',
  'pages.parameterslist.value.ess.3': 'Malware and Access Authorization App',
  'pages.parameterslist.value.ess.4': 'Remote Access App',
  'pages.parameterslist.value.ess.5': 'Remote Access App and Access Authorization App',
  'pages.parameterslist.value.ess.6': 'Remote Access App and Malware',
  'pages.parameterslist.value.ess.7': 'All Cases',
  'pages.parameterslist.value.callst.1': 'Takes search data',
  'pages.parameterslist.value.callst.3': 'Sends the data to the backend',
  'pages.parameterslist.value.callst.7': 'Sends the data to the backend and informs the mobile app through SDK',
  'pages.parameterslist.value.ham.49': 'Active for all apps installed from unknown sources',
  'pages.parameterslist.value.ham.56': 'Active for all devices',
  'pages.parameterslist.showParams': 'Show Parameters',
  'pages.parameterslist.parameters': 'Parameters',
  'pages.parameterslist.message': 'If you want to activate a parameter on this list that you don\'t have, contact Codevo.',

  'pages.pipeline.table.id': 'Id',
  'pages.pipeline.table.description': 'Description',
  'pages.pipeline.table.status': 'Status',

  'pages.browser.detail.web-pages': 'WEB PAGES',

  'topnav.logout': 'Log out',
  'topnav.darkmode': 'Dark Mode',
  'footer.panelVersion': 'panel version : ',
  'footer.bffVersion': 'bff version : ',
  'footer.intranetVersion': 'intranet version : ',

  /* 04 Anomaly */
  'anomaly.noAnomaly': 'No Anomaly',
  'anomaly.rooted': 'Rooted',
  'anomaly.malwareDetected': 'Malware Detected',
  'anomaly.emulator': 'Emulator',
  'anomaly.runtimeHookDetected': 'Runtime Hook Detected ',
  'anomaly.reverseEngineeringToolDetected': 'Reverse Engineering Tool Detected',
  'anomaly.installedFromUnknownSource': 'Install From Unknown Source Detected',
  'anomaly.appDebuggable': 'Debugging Detected',
  'anomaly.fileIntegrityBroken': 'File Integrity Broken',
  'anomaly.simChangeDetected': 'Sim Change Detected',
  'anomaly.remoteAccessAppDetected': 'Remote Access App Detected',
  'anomaly.accessibilityEnabled': 'Accessibility Enabled',
  'anomaly.developerDetected': 'Developer Detected',

  'anomaly.desc.no': 'No description available.',
  'anomaly.desc.CDV-SC-1-1': 'Root management applications detected.',
  'anomaly.desc.CDV-SC-1-2': 'Potential malicious root applications detected.',
  'anomaly.desc.CDV-SC-1-3': 'Root software detected.',
  'anomaly.desc.CDV-SC-1-4': 'Root system properties detected.',
  'anomaly.desc.CDV-SC-1-5': 'Root files detected.',
  'anomaly.desc.CDV-SC-1-6': 'Root keys detected.',
  'anomaly.desc.CDV-SC-1-7': 'Root software detected.',
  'anomaly.desc.CDV-SC-1-8': 'Magisk detected.',
  'anomaly.desc.CDV-SC-1-9': 'Xposed detected.',
  'anomaly.desc.CDV-iSC-1-1': 'Jailbreak files detected.',
  'anomaly.desc.CDV-iSC-1-2': 'Jailbreak directories detected.',
  'anomaly.desc.CDV-iSC-1-3': 'Jailbreak management applications detected.',
  'anomaly.desc.CDV-iSC-1-4': 'Jailbreak ports detected.',
  'anomaly.desc.CDV-iSC-1-5': 'Jailbreak directories detected.',
  'anomaly.desc.CDV-SC-2-1': 'Emulator system properties detected.',
  'anomaly.desc.CDV-SC-2-2': 'Emulator serial number detected.',
  'anomaly.desc.CDV-SC-2-3': 'Emulator phone properties detected.',
  'anomaly.desc.CDV-SC-2-5': 'Genymotion detected.',
  'anomaly.desc.CDV-SC-2-6': 'Emulator sensor properties detected.',
  'anomaly.desc.CDV-SC-2-7': 'Emulator battery properties detected.',
  'anomaly.desc.CDV-SC-2-8': 'Emulator CPU properties detected.',
  'anomaly.desc.CDV-SC-2-9': 'Emulator WiFi properties detected.',
  'anomaly.desc.CDV-SC-2-10': 'Emulator hardware properties detected.',
  'anomaly.desc.CDV-iSC-2-1': 'Simulator environment detected.',
  'anomaly.desc.CDV-iSC-2-2': 'Simulator model detected.',
  'anomaly.desc.CDV-iSC-2-3': 'Simulator files detected.',
  'anomaly.desc.CDV-SC-3-1': 'Unwanted applications detected in memory.',
  'anomaly.desc.CDV-SC-3-2': 'Unwanted libraries detected in memory.',
  'anomaly.desc.CDV-SC-3-3': 'SSL pinning removal detected.',
  'anomaly.desc.CDV-SC-3-4': 'SSL pinning removal detected.',
  'anomaly.desc.CDV-SC-3-5': 'Xposed reverse engineering tool intervention detected.',
  'anomaly.desc.CDV-SC-3-6': 'Xposed reverse engineering tool intervention detected.',
  'anomaly.desc.CDV-AA-3-7': 'Fake location manipulation detected.',
  'anomaly.desc.CDV-AA-3-8': 'Android function tampering detected.',
  'anomaly.desc.CDV-AA-3-9': 'Frida functions detected.',
  'anomaly.desc.CDV-iSC-3-1': 'SSL pinning removal detected.',
  'anomaly.desc.CDV-iSC-3-2': 'Unwanted libraries detected in memory.',
  'anomaly.desc.CDV-iSC-3-3': 'Device function tampering detected.',
  'anomaly.desc.CDV-SC-4-1': 'Frida detected in memory.',
  'anomaly.desc.CDV-SC-4-2': 'Reverse engineering tools detected on the device.',
  'anomaly.desc.CDV-SC-4-3': 'Frida activity detected on the device.',
  'anomaly.desc.CDV-SC-4-4': 'Frida activity detected on the device.',
  'anomaly.desc.CDV-SC-4-5': 'Reverse engineering tools detected on the device.',
  'anomaly.desc.CDV-iSC-4-1': 'Reverse engineering tools detected on the device.',
  'anomaly.desc.CDV-iSC-4-2': 'Reverse engineering tool activity detected on the device.',
  'anomaly.desc.CDV-iSC-4-3': 'Frida activity detected on the device.',
  'anomaly.desc.CDV-SC-6-1': 'Application installed from an unknown source detected.',
  'anomaly.desc.CDV-SC-6-2': 'Application installed from an unknown source detected.',
  'anomaly.desc.CDV-SC-7-1': 'Application detected as debuggable.',
  'anomaly.desc.CDV-SC-7-2': 'Java debugger attached to the application detected.',
  'anomaly.desc.CDV-SC-7-4': 'Debugger attached to the application detected.',
  'anomaly.desc.CDV-iSC-7-1': 'Debugger attached to the application detected.',
  'anomaly.desc.CDV-iSC-7-2': 'Debugger attached to the application detected.',
  'anomaly.desc.CDV-SC-8-1': 'Application Dex and Manifest files modification detected.',
  'anomaly.desc.CDV-SC-8-2': 'Application information modification detected.',
  'anomaly.desc.CDV-SC-8-4': 'Package name or certificate of the application modification detected.',
  'anomaly.desc.CDV-bSC-8-1': 'Package name or certificate of the application modification detected.',
  'anomaly.desc.CDV-bSC-8-2': 'Application signing certificate validation failed.',
  'anomaly.desc.CDV-iSC-8-1': 'Application bundle files validation failed.',
  'anomaly.desc.CDV-iSC-8-2': 'Application profile validation failed.',
  'anomaly.desc.CDV-SC-9-1': 'SIM card change detected.',
  'anomaly.desc.CDV-SC-10-1': 'Remote access application detected.',
  'anomaly.desc.CDV-SC-10-2': 'Remote access application detected.',
  'anomaly.desc.CDV-AA-10-3': 'Active remote access detected.',
  'anomaly.desc.CDV-SC-11-1': 'Application with active accessibility privilege detected.',
  'anomaly.desc.CDV-SC-12-1': 'ADB detected.',
  'anomaly.desc.CDV-SC-12-2': 'Developer mode detected.',
  'anomaly.desc.CDV-iSC-13-1': 'Target app detected.',

  'anomaly.botDetected': 'Bot Detected',
  'anomaly.buhTrapDetected': 'BuhTrap Detected',
  'anomaly.browserSpoofingDetected': 'Browser Spoofing Detected',
  'anomaly.maliciousScriptDetected': 'Malicious Script Detected',
  'anomaly.htmlInjectionDetected': 'HTML Injection Detected',
  'anomaly.clickJackingDetected': 'Clickjacking Detected',
  'anomaly.accessToExternalDomainDetected': 'Access to External Domain Detected',
  'anomaly.virtualMachineDetected': 'Virtual Machine Detected',
  'anomaly.debuggingDetected': 'Debugging Detected',
  'anomaly.extensionDetected': 'Extension Detected',
  'anomaly.remoteDesktopDetected': 'Remote Desktop Detected',

  'anomaly.scan.rooted': 'Root Scan',
  'anomaly.scan.malwareDetected': 'Malware Scan',
  'anomaly.scan.emulator': 'Emulator Scan',
  'anomaly.scan.runtimeHookDetected': 'Runtime Hook Scan',
  'anomaly.scan.reverseEngineeringToolDetected': 'Reverse Engineering Tool Scan',
  'anomaly.scan.installedFromUnknownSource': 'Install From Unknown Source Scan',
  'anomaly.scan.appDebuggable': 'Debug Scan',
  'anomaly.scan.fileIntegrityBroken': 'File Integrity Scan',
  'anomaly.scan.simChangeDetected': 'Sim Change Scan',
  'anomaly.scan.remoteAccessAppDetected': 'Remote Access App Scan',
  'anomaly.scan.isAccessibilityEnabled': 'Accessibility Scan',
  'anomaly.scan.developerDetected': 'Developer Scan',
  'anomaly.scan.otherApplicationDetected': 'Application Detection Scan',

  'anomaly.scan.botDetected': 'Bot Scan',
  'anomaly.scan.buhTrapDetected': 'BuhTrap Scan',
  'anomaly.scan.browserSpoofingDetected': 'Browser Spoofing Scan',
  'anomaly.scan.maliciousScriptDetected': 'Malicious Script Scan',
  'anomaly.scan.htmlInjectionDetected': 'HTML Injection Scan',
  'anomaly.scan.clickJackingDetected': 'Clickjacking Scan',
  'anomaly.scan.accessToExternalDomainDetected': 'Access to External Domain Scan',
  'anomaly.scan.virtualMachineDetected': 'Virtual Machine Scan',
  'anomaly.scan.debuggingDetected': 'Debugging Scan',
  'anomaly.scan.extensionDetected': 'Extension Scan',
  'anomaly.scan.remoteDesktopDetected': 'Remote Desktop Scan',

  /* Reports */
  'reports.pha.title': 'PHA REPORTS',

  /* File Uplaod */
  'pages.file.upload.download.result': 'Download Result',
  'pages.file.upload.select.files.from.computer': 'Select files from computer',
  'pages.file.upload.upload': 'Upload File',
  'pages.file.upload.or': 'or',
  'pages.file.upload.drag.files.here': 'Drag files here',
  'pages.file.upload.show.file.history': 'Show File History',
  'pages.file.upload.hide.file.history': 'Hide File History',
  'pages.file.upload.name': 'Name',
  'pages.file.upload.size': 'Size',
  'pages.file.upload.status': 'Status',

  /* Icon */
  'icon.show': 'Show Icon',
  'icon.hide': 'Hide Icon',

  'rules.apply-all-changes': 'Apply All Changes',
  'rules.all': 'All',
  'rules.active': 'Active',
  'rules.inactive': 'Inactive',
  'rules.anomaly-types': 'Anomaly Types',
  'rules.commands': 'Commands',
  'rules.search': 'Search',
  'rules.status': 'Status',
  'rules.tooltip.crash': 'Octopus shuts down the mobile application upon detecting the relevant anomaly.',
  'rules.tooltip.block': 'When the relevant anomaly is detected, Secure Server (Seal) forbids operations on the device. Logging in or any other transaction is disabled.',
  'rules.tooltip.action': 'A popup is displayed on the mobile device in order to show the information message to the customer. FORCE_STOP: The customer can not continue using the application. INFO: The customer can continue using the application by clicking the \"Continue\" button.',
  'rules.tooltip.tag': 'Octopus and Secure Server take no action. Only a tag is added to the device for the relevant scan. ACTION, CRASH and BLOCK rules are executed basen on these tags.',
  'rules.tooltip.dialog': 'An alert is displayed on the device with one or more choices. The message is displayed by Octopus. FORCE_STOP: The customer can not continue using the application. INFO: The customer can continue using the application by clicking the \"Continue\" button.',
  'rules.tooltip.other': 'Other rules that are not included in any group (ACTION, CRASH, BLOCK, TAG).',
};

import {
  SET_CURRENT_PAGE,
  SET_SELECTED_PAGE_SIZE,
  SET_SELECTED_ORDER_OPTION,
  SET_SEARCH,
  OPEN_SUB_MENU,
  SET_HAS_NEXT_MALWARE,
} from '../actions';

const INIT_STATE = {
  currentPage: 1,
  selectedPageSize: 8,
  selectedOrderOption: {
    column: 'firstSeen',
    label: 'First Seen Date',
  },
  hasNext: false,
  search: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CURRENT_PAGE:
      return { ...state, currentPage: action.payload };
    case SET_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.payload, currentPage: 1 };
    case SET_SELECTED_ORDER_OPTION:
      return { ...state, selectedOrderOption: action.payload, currentPage: 1 };
    case SET_SEARCH:
      return { ...state, search: action.payload, currentPage: 1 };
    case SET_HAS_NEXT_MALWARE:
      return { ...state, hasNext: action.payload };
    case OPEN_SUB_MENU:
      return INIT_STATE;
    default:
      return { ...state };
  }
};

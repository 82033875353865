import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey, langKey
} from '../constants/defaultValues';
import lang from '../lang';

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getDirection -> error", error)
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};
export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem('direction', direction);
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setDirection -> error", error)
  }
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getCurrentColor -> error", error)
    currentColor = defaultColor
  }
  return currentColor;
};

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentColor -> error", error)
  }
};

export const getCurrentLocale = () => {
  let currentLocale = defaultLocale;
  try {
    if (localStorage.getItem(langKey)) {
      currentLocale = localStorage.getItem(langKey);
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getCurrentLocale -> error", error)
    currentLocale = defaultLocale
  }
  return currentLocale;
}

export const setCurrentLocale = (lang) => {
  try {
    localStorage.setItem(langKey, lang);
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentLocale -> error", error)
  }
}

export const getCurrentRadius = () => {
  let currentRadius = 'rounded';
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getCurrentRadius -> error", error)
    currentRadius = 'rounded'
  }
  return currentRadius;
};
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentRadius -> error", error)
  }
};

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language = localStorage.getItem('currentLanguage') &&
      localeOptions.filter(
        (x) => x.id === localStorage.getItem('currentLanguage')
      ).length > 0
      ? localStorage.getItem('currentLanguage')
      : defaultLocale;
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getCurrentLanguage -> error", error)
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentLanguage -> error", error)
  }
};

export const getCurrentUser = () => {
  let user = null;
  try {
    user =
      localStorage.getItem('octopus_current_user') != null
        ? JSON.parse(localStorage.getItem('octopus_current_user'))
        : null;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
    user = null;
  }
  return user;
};

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem('octopus_current_user', JSON.stringify(user));
    } else {
      localStorage.removeItem('octopus_current_user');
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};

export const getBFFVersion = () => {
  let version = null;
  try {
    version =
      localStorage.getItem('octopus_bff_version') != null
        ? JSON.parse(localStorage.getItem('octopus_bff_version'))
        : null;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getBFFVersion -> error', error);
    version = null;
  }
  return version;
};

export const setBFFVersion = (version) => {
  try {
    if (version) {
      localStorage.setItem('octopus_bff_version', JSON.stringify(version));
    } else {
      localStorage.removeItem('octopus_bff_version');
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setBFFVersion -> error', error);
  }
};

export const getIntranetVersion = () => {
  let version = null;
  try {
    version =
      localStorage.getItem('octopus_intranet_version') != null
        ? JSON.parse(localStorage.getItem('octopus_intranet_version'))
        : null;
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js  : getIntranetVersion -> error',
      error
    );
    version = null;
  }
  return version;
};

export const setIntranetVersion = (version) => {
  try {
    if (version) {
      localStorage.setItem('octopus_intranet_version', JSON.stringify(version));
    } else {
      localStorage.removeItem('octopus_intranet_version');
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setIntranetVersion -> error',
      error
    );
  }
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const getBrowserLogoName = (product) => {
  let browserName = product?.browserInfo?.browserName;
  let extension = 'svg';
  if (browserName) {
    if (browserName === 'SRWare Iron') extension = 'png';
    browserName = `${browserName.toLowerCase().replaceAll(' ', '_')}_logo.${extension}`;
  } else {
    browserName = 'unknown_logo.svg';
  }
  return browserName;
};

export const getBrowserBrand = (product) => {
  if (
    product?.browserInfo.browserProperties?.identifiers.includes('OPERA_GX')
  ) {
    return 'Opera GX';
  }
  if (product?.browserInfo?.browserProperties?.identifiers.includes('OPERA')) {
    return 'Opera';
  }
  if (product?.browserInfo?.browserProperties?.identifiers.includes('EDGE')) {
    return 'Microsoft Edge';
  }
  if (product?.browserInfo?.browserProperties?.identifiers.includes('BRAVE')) {
    return 'Brave';
  }
  if (
    product?.browserInfo?.browserProperties?.identifiers.includes(
      'SAMSUNG_INTERNET'
    )
  ) {
    return 'Samsung Internet';
  }
  if (
    product?.browserInfo?.browserProperties?.identifiers?.includes('FALKON')
  ) {
    return 'Falkon';
  }
  if (
    product?.browserInfo?.browserProperties?.identifiers?.includes(
      'SRWARE_IRON'
    )
  ) {
    return 'SRWare Iron';
  }
  if (
    product?.browserInfo?.browserProperties?.identifiers.includes('CHROMIUM')
  ) {
    return 'Chromium';
  }
  if (product?.browserInfo?.browserProperties?.identifiers.includes('CHROME')) {
    return 'Chrome';
  }
  if (
    product?.browserInfo?.browserProperties?.identifiers.includes('SEAMONKEY')
  ) {
    return 'SeaMonkey';
  }
  if (
    product?.browserInfo?.browserProperties?.identifiers.includes('FIREFOX')
  ) {
    return 'Firefox';
  }
  if (product?.browserInfo?.browserProperties?.identifiers.includes('SAFARI')) {
    return 'Safari';
  }
  if (
    product?.browserInfo?.browserProperties?.identifiers.includes('IE11') ||
    product?.browserInfo?.browserProperties?.identifiers.includes('IE10') ||
    product?.browserInfo?.browserProperties?.identifiers.includes('IE')
  ) {
    return 'Internet Explorer';
  }
  return 'Unknown';
};

export const getBrowserOS = (appVersion, userAgent) => {
  let os;
  const clientStrings = [
    { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
    { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
    { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
    { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
    { s: 'Windows Vista', r: /Windows NT 6.0/ },
    { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
    { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
    { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
    { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
    { s: 'Windows 98', r: /(Windows 98|Win98)/ },
    { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
    { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
    { s: 'Windows CE', r: /Windows CE/ },
    { s: 'Windows 3.11', r: /Win16/ },
    { s: 'Android', r: /Android/ },
    { s: 'Open BSD', r: /OpenBSD/ },
    { s: 'Sun OS', r: /SunOS/ },
    { s: 'Chrome OS', r: /CrOS/ },
    { s: 'Linux', r: /(Linux|X11(?!.*CrOS))/ },
    { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
    { s: 'macOS', r: /(Mac OS X|Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
    { s: 'QNX', r: /QNX/ },
    { s: 'UNIX', r: /UNIX/ },
    { s: 'BeOS', r: /BeOS/ },
    { s: 'OS/2', r: /OS\/2/ },
    {
      s: 'Search Bot',
      r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/,
    },
  ];
  // eslint-disable-next-line no-restricted-syntax
  for (const id of Object.values(clientStrings)) {
    if (id.r.test(userAgent)) {
      os = id.s;
      break;
    }
  }

  let osVersion;

  if (/Windows/.test(os)) {
    // eslint-disable-next-line prefer-destructuring
    osVersion = /Windows (.*)/.exec(os)[1];
    os = 'Windows';
  }

  switch (os) {
    case 'macOS':
    case 'Android':
      // eslint-disable-next-line prefer-destructuring
      osVersion = /(?:Android|Mac OS|Mac OS X|MacPPC|MacIntel|Mac_PowerPC|Macintosh) ([._\d]+)/.exec(
        userAgent
      );
      osVersion = osVersion ? osVersion[1].replaceAll('_', '.') : undefined;
      break;

    case 'iOS':
      osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(appVersion);
      osVersion = `${osVersion[1]}.${osVersion[2]}.${osVersion[3] || 0}`;
      break;
    default:
      break;
  }

  return `${os} (${osVersion})`;
};

const modelMap = {
  "i386": "Simulator",
  "x86_64": "Simulator",
  "arm64": "Simulator",
  "iPhone1,1": "",
  "iPhone1,2": "3G",
  "iPhone2,1": "3GS",
  "iPhone3,1": "4",
  "iPhone3,2": "4 GSM Rev A",
  "iPhone3,3": "4 CDMA",
  "iPhone4,1": "4S",
  "iPhone5,1": "5 (GSM)",
  "iPhone5,2": "5 (GSM+CDMA)",
  "iPhone5,3": "5C (GSM)",
  "iPhone5,4": "5C (Global)",
  "iPhone6,1": "5S (GSM)",
  "iPhone6,2": "5S (Global)",
  "iPhone7,1": "6 Plus",
  "iPhone7,2": "6",
  "iPhone8,1": "6s",
  "iPhone8,2": "6s Plus",
  "iPhone8,4": "SE (GSM)",
  "iPhone9,1": "7",
  "iPhone9,2": "7 Plus",
  "iPhone9,3": "7",
  "iPhone9,4": "7 Plus",
  "iPhone10,1": "8",
  "iPhone10,2": "8 Plus",
  "iPhone10,3": "X Global",
  "iPhone10,4": "8",
  "iPhone10,5": "8 Plus",
  "iPhone10,6": "X GSM",
  "iPhone11,2": "XS",
  "iPhone11,4": "XS Max",
  "iPhone11,6": "XS Max Global",
  "iPhone11,8": "XR",
  "iPhone12,1": "11",
  "iPhone12,3": "11 Pro",
  "iPhone12,5": "11 Pro Max",
  "iPhone12,8": "SE 2nd Gen",
  "iPhone13,1": "12 Mini",
  "iPhone13,2": "12",
  "iPhone13,3": "12 Pro",
  "iPhone13,4": "12 Pro Max",
  "iPhone14,2": "13 Pro",
  "iPhone14,3": "13 Pro Max",
  "iPhone14,4": "13 Mini",
  "iPhone14,5": "13",
  "iPhone14,6": "SE 3rd Gen",
  "iPhone14,7": "14",
  "iPhone14,8": "14 Plus",
  "iPhone15,2": "14 Pro",
  "iPhone15,3": "14 Pro Max",
  "iPhone15,4": "15",
  "iPhone15,5": "15 Plus",
  "iPhone16,1": "15 Pro",
  "iPhone16,2": "15 Pro Max"
}

export const getDeviceModel = (deviceModel) => {
  if(modelMap.hasOwnProperty(deviceModel)) {
    const prefix = "iPhone";
    if(deviceModel.includes(prefix)) {
        const deviceNumber = deviceModel.substring(prefix.length);
      return [modelMap[deviceModel], deviceNumber];
    }
    return [modelMap[deviceModel], deviceModel];
  }
  return [deviceModel, ""];
}
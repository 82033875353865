import {
  SET_CURRENT_PAGE_DEVICE,
  SET_SELECTED_PAGE_SIZE_DEVICE,
  SET_SELECTED_ORDER_OPTION_DEVICE,
  SET_SEARCH_DEVICE,
  SET_HAS_NEXT_DEVICE,
} from '../actions';

export const setCurrentPageDevice = (payload) => ({
  type: SET_CURRENT_PAGE_DEVICE,
  payload,
});

export const setSelectedPageSizeDevice = (payload) => ({
  type: SET_SELECTED_PAGE_SIZE_DEVICE,
  payload,
});

export const setSelectedOrderOptionDevice = (payload) => ({
  type: SET_SELECTED_ORDER_OPTION_DEVICE,
  payload,
});

export const setSearchDevice = (payload) => ({
  type: SET_SEARCH_DEVICE,
  payload,
});

export const setHasNextDevice = (payload) => ({
  type: SET_HAS_NEXT_DEVICE,
  payload,
});
